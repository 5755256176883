'use client';
import { useEffect } from 'react';

export default function useScrollLock(bool = true) {
  useEffect(() => {
    const body = document.querySelector('body');
    if (bool && body && body.style.overflow !== 'hidden') {
      body.style.overflow = 'hidden';

      return () => {
        const body = document.querySelector('body');
        if (body) body.style.overflow = 'unset';
      };
    }
    return () => {};
  }, [bool]);
}
