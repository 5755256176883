'use client';
import * as React from 'react';
import { twMerge } from '@dreamplan/utils';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { VariantProps, cva } from 'class-variance-authority';
import clsx from 'clsx';
import { OnKeyPress } from '../../utils/useKeyPress';
import useScrollLock from '../../utils/useScrollLock';

export function ModalBackground({ onClose }: { onClose: () => void }) {
  return (
    <div
      aria-hidden={true}
      className={clsx('bg-surface-dark fixed inset-0 z-10 bg-opacity-75 backdrop-blur-sm')}
      onClick={onClose}
    ></div>
  );
}

export function CloseModalButton({
  onClose,
  className,
}: {
  onClose: () => void;
  className?: string;
}) {
  return (
    <button
      className={clsx(
        className || 'absolute right-4 top-4 flex items-center justify-center space-x-0.5',
      )}
      onClick={onClose}
      data-testid="close-modal-btn"
    >
      <p className="sr-only">close modal</p>
      <XMarkIcon className="inline h-6 w-6 cursor-pointer" strokeWidth={2} />
    </button>
  );
}

const modal = cva(
  [
    'relative z-[1000] overflow-auto bg-white shadow-md shadow-white/10',
    // important: on mobile always occupy full screen
    'h-full w-full md:rounded-2xl',
  ],
  {
    variants: {
      size: {
        small: 'md:max-w-sm',
        medium: 'md:max-w-2xl',
        large: 'md:max-w-3xl',
        full: 'md:max-w-full',
        unset: 'w-fit h-fit',
      },
    },

    compoundVariants: [
      {
        size: ['small', 'medium', 'large'],
        class: 'lg:h-min p-6 sm:p-10',
      },
    ],

    defaultVariants: {
      size: 'medium',
    },
  },
);

type ModalStyleProps = VariantProps<typeof modal>;

type ModalProps = {
  onClose: () => void;
  children: React.ReactNode;
  hideClose?: boolean;
  className?: string;
} & ModalStyleProps;

const Modal: React.FC<ModalProps> = ({ children, onClose, hideClose, className, size }) => {
  useScrollLock();
  return (
    <div
      aria-modal={true}
      aria-hidden={false}
      tabIndex={-1}
      className={clsx('fixed inset-0 z-[1000] w-full  overflow-y-auto')}
      role={'dialog'}
      data-testid={'modal'}
    >
      {onClose && (
        <>
          <OnKeyPress callback={onClose} targetKey="Escape" />
          <ModalBackground onClose={onClose} />
        </>
      )}

      <div
        className={clsx(
          // Center the modal |->[  ]<-|
          'flex h-full w-full items-center justify-center',
          'overflow-hidden',
        )}
        role={'document'}
      >
        <div
          className={clsx(
            'relative z-[100] overflow-auto',
            className ? twMerge(modal({ size }), className) : modal({ size }),
          )}
        >
          {!hideClose && <CloseModalButton onClose={onClose} />}
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
