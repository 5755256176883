'use client';
import * as React from 'react';

export default function useFirstInteraction() {
  const [firstInteraction, setFirstInteraction] = React.useState(false);

  React.useEffect(() => {
    const callback = () => {
      setFirstInteraction(true);
    };
    if (!firstInteraction) {
      window.addEventListener('mousemove', callback);
      window.addEventListener('scroll', callback);
    } else {
      window.removeEventListener('mousemove', callback);
      window.removeEventListener('scroll', callback);
    }
  }, [firstInteraction]);
  return firstInteraction;
}
