'use client';

import React, { useEffect, useState, Fragment } from 'react';

export default function useKeyPress(targetKey: string) {
  const [keyPressed, setKeyPressed] = useState<boolean>(false);

  function downHandler({ key }: any) {
    if (key === targetKey) {
      setKeyPressed(true);
    }
  }

  const upHandler = ({ key }: any) => {
    if (key === targetKey) {
      setKeyPressed(false);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);

    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, []);

  return { keyPressed };
}

export function OnKeyPress({ callback, targetKey }: { targetKey: string; callback: () => void }) {
  const { keyPressed } = useKeyPress(targetKey);
  useEffect(() => {
    if (keyPressed) {
      callback();
    }
  }, [keyPressed]);
  return <Fragment></Fragment>;
}
