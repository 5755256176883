'use client';
import * as React from 'react';
import Link from 'next/link';
import { useLocale } from '../../utils';

type LocaleLinkProps = React.AnchorHTMLAttributes<HTMLAnchorElement>;

export const LocaleLink = React.forwardRef<HTMLAnchorElement, LocaleLinkProps>((props, ref) => {
  const { children, href = '', ...rest } = props;
  const locale = useLocale();
  const beginsWithSlash = href?.startsWith('/');
  const hrefWithLocale = beginsWithSlash ? `/${locale}${href}` : `/${locale}/${href}`;

  return (
    <Link href={hrefWithLocale} ref={ref} {...rest}>
      {children}
    </Link>
  );
});

LocaleLink.displayName = 'LocaleLink';
