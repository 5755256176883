export async function isAnalyticsEnabled() {
  const enabledEnvirons = ['production', 'preview'];
  const allowedByEnvironment = enabledEnvirons.indexOf(process.env.NEXT_PUBLIC_VERCEL_ENV!) !== -1;

  const cachedIPBlockReponse = JSON.parse(window.sessionStorage.getItem('ip-services') || '{}');

  if (Object.keys(cachedIPBlockReponse).length === 0) {
    const IPBlockResponse = await (await fetch('/api/ip')).json();
    window.sessionStorage.setItem('ip-services', JSON.stringify(IPBlockResponse));
    return (!IPBlockResponse?.disableAnalytics as boolean) && allowedByEnvironment;
  } else {
    return (!cachedIPBlockReponse?.disableAnalytics as boolean) && allowedByEnvironment;
  }
}
