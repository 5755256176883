import * as React from 'react';
import clsx from 'clsx';
const sizes = {
  small: 'w-6 h-6',
  medium: 'w-10 h-10',
  large: 'w-16 h-16',
  xlarge: 'w-20 h-20',
};
export default function SingleBorderedSpinner({
  size = 'small',
  className = '',
}: {
  size?: 'small' | 'medium' | 'large' | 'xlarge';
  className?: string;
}) {
  return (
    <div
      data-testid="spinner"
      className={clsx(
        'animate-spin rounded-full border-slate-300 border-t-blue-500',
        size === 'small' && 'border-2 border-t-2',
        size === 'medium' && 'border-2 border-t-2',
        size === 'large' && 'border-4 border-t-4',
        size === 'xlarge' && 'border-8 border-t-8',

        sizes[size],
        className,
      )}
    ></div>
  );
}
