'use client';
import { signIn } from 'next-auth/react';
import { useAuth } from '@dreamplan/shared-ui/client/auth';

export function useAuthenticationExtensions() {
  const { fetchUserData, user, onEditUser } = useAuth();

  const guestSignIn = async () => {
    if (user.type === 'unsigned') {
      const result = await signIn('credentials', { redirect: false });

      if (result?.error) {
        console.error(result.error);
        throw new Error('Failed to sign-in');
      }

      await fetchUserData();
    } else {
      console.warn('User appears to be already signed-in');
    }
  };

  const implicitSignIn = async (email: string, gid: string, name?: string) => {
    const args: Parameters<typeof signIn>[1] = {
      email: email,
      guid: gid,
      redirect: false,
    };

    if (name) {
      args.name = name;
    }

    const response = await signIn('ImplicitAuth', args);
    if (response?.ok && response.error == null) {
      await fetchUserData(); // sync user data
    } else {
      console.error(response?.error ?? 'Failed to implicitly sign-in');
    }
  };

  const updateUserCredentials = async (cred: Partial<{ email: string; name: string }>) => {
    await onEditUser(cred);
    await fetchUserData();
  };

  return {
    guestSignIn,
    implicitSignIn,
    updateUserCredentials,
  };
}
