'use client';
import * as React from 'react';
import { useTranslation } from '@dreamplan/i18n';
import { useLocale, Text } from '@dreamplan/ui';
import SingleBorderedSpinner from '@dreamplan/ui/components/Spinners/SingleBorderSpinner';
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { useRouter } from 'next/navigation';
import { signOut } from 'next-auth/react';
import { useAnalytics } from '../analytics';
import { useAuth, AuthenticationStatus } from '@dreamplan/shared-ui/client/auth';

function cleanStorage() {
  // NOTE: This will not delete HTTPS only cookies, and cookies set on path or domain
  document.cookie.split(';').forEach((c) => {
    document.cookie = c.trim().split('=')[0] + '=;' + 'expires=Thu, 01 Jan 1970 00:00:00 UTC;';
  });

  window.localStorage.clear();
}

export const SignOutPage = () => {
  const { reset } = useAnalytics();
  const [status, setStatus] = React.useState<AuthenticationStatus>({
    status: 'idle',
  });
  const { t } = useTranslation('auth');
  const { user } = useAuth();
  const router = useRouter();
  let locale = useLocale();

  React.useEffect(() => {
    setStatus({ status: 'loading' });
    if (user) {
      const { location } = window;
      const { search } = location;
      const queryParams = new URLSearchParams(search);
      const redirect = queryParams.get('redirect');
      signOut({ redirect: true, callbackUrl: `/${redirect ?? ''}` });
      reset && reset();
      cleanStorage();
      setStatus({ status: 'success' });
    } else {
      router.push(locale === 'en' ? '/en' : '/');
    }
  }, [user]);

  return (
    <main className="flex min-h-screen w-full items-center justify-center">
      <div className="flex max-w-lg items-center justify-center p-5">
        {status.status === 'loading' && <SingleBorderedSpinner size="large" />}
        {status.status === 'success' && (
          <div className="flex flex-col items-center justify-center">
            <CheckCircleIcon className="h-16 w-16 text-green-500" />
            <Text variant={'h4'} className="font-semibold tracking-wide">
              {t('sign_out_success_message')}
            </Text>
          </div>
        )}
        {status.status === 'error' && (
          <div className="flex items-center justify-center">
            <ExclamationCircleIcon className="h-16 w-16 text-red-600" />
            <Text variant={'h4'} className="font-semibold tracking-wide">
              {t('sign_out_failed_message', { message: status.message ?? '' })}
            </Text>
          </div>
        )}
      </div>
    </main>
  );
};
